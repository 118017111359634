<template>
  <main class="text-center">
    <div class="bg-secondary pt-3 pb-2">
      <div class="container">
        <h1>{{ $t("News.Header.Title") }}</h1>
      </div>
    </div>
    <div class="container mt-3">
      <div class="row mx-1 py-3 text-start bg-secondary">
        <b>{{ $t("News.Years[1].News[0].Title") }}</b>
      </div>
      <div class="row pb-1 mx-1 mb-3 bg-secondary">
        <div class="col-xxl-5 mb-3 mb-sm-0 text-start">
          <img
            width="473"
            height="167"
            alt="GIFA logo"
            src="../assets/images/GIFA2019.jpg"
            class="custom-image-size"
          />
        </div>
        <div class="col-xxl-7 text-start align-self-center pt-3 pt-xl-0">
          <p>
            {{ $t("News.Years[1].News[0].Metadata") }}
          </p>
          <div class="ratio ratio-16x9">
            <video poster="../assets/images/GIFA2019-thumbnail.jpg" controls>
              <source src="../assets/videos/GIFA2019.mp4" type="video/mp4" />
              {{ $t("General.Video.NotSupported") }}
            </video>
          </div>
          <p class="pt-1">
            {{ $t("News.Years[1].News[0].Content") }}
          </p>
          <router-link
            :to="'/' + $i18n.locale + '/news/gifa-2019'"
            class="p-0"
            >{{ $t("General.ReadMore") }}</router-link
          >
        </div>
      </div>
      <div class="row mx-1 py-3 text-start bg-secondary">
        <b>{{ $t("News.Years[2].News[0].Title") }}</b>
      </div>
      <div class="row mb-3 mx-1 pb-1 bg-secondary">
        <div class="col-xxl-5 mb-3 mb-sm-0 text-start">
          <img
            src="../assets/images/strips.jpg"
            alt="Strips image"
            width="473"
            height="351"
            class="custom-image-size"
          />
        </div>
        <div class="col-xxl-7 text-start align-self-center pt-3 pt-xl-0">
          <p>
            {{ $t("News.Years[2].News[0].Metadata") }}
          </p>
          <p>
            {{ $t("News.Years[2].News[0].Content") }}
          </p>
          <router-link
            :to="
              '/' +
              $i18n.locale +
              '/news/mica-slip-plane-for-ferrous-and-non-ferrous-foundries'
            "
            class="p-0"
            >{{ $t("General.ReadMore") }}</router-link
          >
        </div>
      </div>
      <div class="row mx-1 py-3 text-start bg-secondary">
        <b>{{ $t("News.Years[2].News[1].Title") }}</b>
      </div>
      <div class="row mb-3 mx-1 pb-1 bg-secondary">
        <div class="col-xxl-5 mb-3 mb-sm-0 text-start">
          <img
            width="473"
            height="167"
            alt="GIFA logo"
            src="../assets/images/GIFA2015.jpg"
            class="custom-image-size"
          />
        </div>
        <div class="col-xxl-7 text-start align-self-center pt-3 pt-xl-0">
          <p>
            {{ $t("News.Years[2].News[1].Metadata") }}
          </p>
          <p>
            {{ $t("News.Years[2].News[1].Content") }}
          </p>
          <router-link
            :to="'/' + $i18n.locale + '/news/gifa-2015'"
            class="p-0"
            >{{ $t("General.ReadMore") }}</router-link
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("News.Document.Title"));
  },
};
</script>