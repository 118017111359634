<template>
  <main class="text-center">
    <div class="bg-primary py-3 pb-2">
      <div class="container">
        <h1>{{ $t("Contact.Header.Title") }}</h1>
      </div>
    </div>
    <div class="container">
      <div class="row my-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2571.8198294549484!2d8.634011615729094!3d49.86462897939877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd7083c9ae9d39%3A0x31cff53358e7c8f5!2sBerliner+Allee+65%2C+64295+Darmstadt%2C+Germany!5e0!3m2!1sen!2sin!4v1493010595910"
          width="1200"
          height="450"
          frameborder="0"
          allowfullscreen=""
        ></iframe>
      </div>
      <div class="row gap-5 my-5">
        <div class="col-sm text-start">
          <h2 class="text-primary">{{ $t("Contact.Title") }}</h2>
          <p class="text-primary">{{ $t("Contact.Description") }}</p>
          <p>
            <br />
            <b>{{ $t("Contact.ContactInfo") }}</b
            ><br /><br />
            {{ $t("General.Address.Company") }}<br />
            {{ $t("General.Address.Street") }}<br />
            {{ $t("General.Address.Location") }}<br />
            {{ $t("General.Address.Country") }}<br /><br />
            {{ $t("General.PhoneNumbers[0]") }}<br />
            {{ $t("General.PhoneNumbers[1]") }}<br /><br />
            <a href="mailto:davidg@newform.de"> davidg@newform.de </a>
          </p>
        </div>
        <div class="col-sm text-start">
          <form
            id="serverless-contact-form"
            @submit="checkForm"
            novalidate="true"
          >
            <b>{{ $t("Contact.ContactInfo") }}</b>
            <div class="row mb-3">
              <div class="col-sm-12">
                <label for="name">{{ $t("Contact.Form.Name") }}</label>
                <input
                  id="name"
                  v-model="name"
                  name="name"
                  type="text"
                  class="form-control"
                />
              </div>
              <div
                v-if="
                  errors.includes($t('Contact.Form.Validation.Name.Required'))
                "
                class="col-sm text-start text-danger"
              >
                {{ $t("Contact.Form.Validation.Name.Required") }}
              </div>
            </div>
            <div class="row mb-3 pt-1">
              <div class="col-sm-12">
                <label for="mail">{{ $t("Contact.Form.Mail") }}</label>
                <input
                  id="mail"
                  v-model="mail"
                  name="mail"
                  type="text"
                  class="form-control"
                />
              </div>
              <div
                v-if="
                  errors.includes($t('Contact.Form.Validation.Mail.Required'))
                "
                class="col-sm text-start text-danger"
              >
                {{ $t("Contact.Form.Validation.Mail.Required") }}
              </div>
              <div
                v-else-if="
                  errors.includes($t('Contact.Form.Validation.Mail.Valid'))
                "
                class="col-sm-6 text-start text-danger"
              >
                {{ $t("Contact.Form.Validation.Mail.Valid") }}
              </div>
            </div>
            <div class="row mb-3 pt-1">
              <div class="col-sm-12">
                <label for="phone">{{ $t("Contact.Form.Phone") }}</label>
                <input
                  id="phone"
                  v-model="phone"
                  name="phone"
                  type="tel"
                  class="form-control"
                />
              </div>
              <div
                v-if="
                  errors.includes($t('Contact.Form.Validation.Phone.Required'))
                "
                class="col-sm text-start text-danger"
              >
                {{ $t("Contact.Form.Validation.Phone.Required") }}
              </div>
              <div
                v-else-if="
                  errors.includes($t('Contact.Form.Validation.Phone.Valid'))
                "
                class="col-sm-6 text-start text-danger"
              >
                {{ $t("Contact.Form.Validation.Phone.Valid") }}
              </div>
            </div>
            <div class="row mb-3 pt-1">
              <div class="col-sm-12">
                <label for="address">{{ $t("Contact.Form.Address") }}</label>
                <input
                  id="address"
                  v-model="address"
                  name="address"
                  type="text"
                  class="form-control"
                />
              </div>
              <div
                v-if="
                  errors.includes(
                    $t('Contact.Form.Validation.Address.Required')
                  )
                "
                class="col-sm text-start text-danger"
              >
                {{ $t("Contact.Form.Validation.Address.Required") }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12">
                <label for="message">{{ $t("Contact.Form.Message") }}</label>
                <textarea
                  id="message"
                  v-bind:value="message"
                  v-on:input="message = $event.target.value"
                  name="message"
                  class="form-control"
                />
              </div>
              <div
                v-if="
                  errors.includes(
                    $t('Contact.Form.Validation.Message.Required')
                  )
                "
                class="col-sm text-start text-danger"
              >
                {{ $t("Contact.Form.Validation.Message.Required") }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <button
                  type="submit"
                  name="submit"
                  class="btn btn-primary fw-bold"
                >
                  {{ $t("Contact.Form.Submit") }}
                </button>
              </div>
            </div>
            <div id="modalMailSuccess" class="modal" tabindex="-1">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header border-0">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="row mb-4">
                      <img
                        src="../assets/images/email_success.svg"
                        alt="Email success"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div class="row mb-4 justify-content-center text-center">
                      {{ $t("Contact.Form.Response.Success[0]") }}
                      <br />
                      {{ $t("Contact.Form.Response.Success[1]") }}
                    </div>
                  </div>
                  <div class="modal-footer justify-content-center border-0">
                    <button
                      type="button"
                      class="
                        btn btn-primary
                        fw-bold
                        rounded-pill
                        text-nowrap
                        w-25
                        px-3
                      "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      {{ $t("Contact.Form.Response.Ok") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="modalMailError" class="modal" tabindex="-1">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header border-0">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="row mb-4">
                      <img
                        src="../assets/images/email_error.svg"
                        alt="Email error"
                        width="75"
                        height="75"
                      />
                    </div>
                    <div class="row mb-4 justify-content-center text-center">
                      {{ $t("Contact.Form.Response.Fail[0]") }}
                      <br />
                      {{ $t("Contact.Form.Response.Fail[1]") }}
                    </div>
                  </div>
                  <div class="modal-footer justify-content-center border-0">
                    <button
                      type="button"
                      class="
                        btn btn-primary
                        fw-bold
                        rounded-pill
                        text-nowrap
                        w-25
                        px-3
                      "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      {{ $t("Contact.Form.Response.Ok") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../js/localization";
import { sendData, validEmail } from "../js/contact";
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      errors: [],
      name: null,
      address: null,
      phone: null,
      message: null,
      mail: null,
    };
  },
  methods: {
    async checkForm(e) {
      e.preventDefault();

      this.errors = [];

      if (!this.name) {
        this.errors.push(this.$t("Contact.Form.Validation.Name.Required"));
      }
      if (!this.mail) {
        this.errors.push(this.$t("Contact.Form.Validation.Mail.Required"));
      } else if (!validEmail(this.mail)) {
        this.errors.push(this.$t("Contact.Form.Validation.Mail.Valid"));
      }
      if (!this.phone) {
        this.errors.push(this.$t("Contact.Form.Validation.Phone.Required"));
      } else if (isNaN(this.phone)) {
        this.errors.push(this.$t("Contact.Form.Validation.Phone.Valid"));
      }
      if (!this.message) {
        this.errors.push(this.$t("Contact.Form.Validation.Message.Required"));
      }

      if (!this.errors.length) {
        const ok = await sendData();
        var modalMailSuccess = new Modal(
          document.getElementById("modalMailSuccess")
        );
        var modalMailError = new Modal(
          document.getElementById("modalMailError")
        );
        if (ok) {
          modalMailSuccess.show();
        } else {
          modalMailError.show();
        }
      }
    },
  },
  mounted() {
    setDocumentTitle(this.$t("Contact.Document.Title"));
  },
};
</script>