import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Products from '../views/Products.vue'
import News from '../views/News.vue'
import News01 from '../views/news/Mica slip plane for ferrous and non-ferrous foundries.vue'
import News02 from '../views/news/GIFA 2015.vue'
import News03 from '../views/news/GIFA 2019.vue'
import LegalNotice from '../views/LegalNotice.vue'
import Application from '../views/Application.vue'
import AboutUs from '../views/AboutUs.vue'
import Privacy from '../views/Privacy.vue'
import Contact from '../views/Contact.vue'
import EmptyRouterView from '../views/EmptyRouterView.vue'
import { Trans } from '../js/translation'

const routes = [
  {
    path: "/",
    component: {
      template: "<router-view></router-view>"
    },
    redirect: { name: 'Home', params: { lang: Trans.defaultLanguage } }
  },
  {
    path: '/:lang',
    name: 'Lang',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'products',
        name: 'Products',
        component: Products
      },
      {
        path: 'news',
        name: 'News',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'News',
            component: News
          },
          {
            path: 'mica-slip-plane-for-ferrous-and-non-ferrous-foundries',
            name: 'News01',
            component: News01
          },
          {
            path: 'gifa-2015',
            name: 'News02',
            component: News02
          },
          {
            path: 'gifa-2019',
            name: 'News03',
            component: News03
          }
        ]
      },
      {
        path: 'application',
        name: 'Application',
        component: Application
      },
      {
        path: 'aboutus',
        name: 'AboutUs',
        component: AboutUs
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: Privacy
      },
      {
        path: 'contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: 'legalnotice',
        name: 'LegalNotice',
        component: LegalNotice
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  var btn = document.getElementById("btnDismissModal");
  if (btn) {
    btn.click();
  }
  next();
});

export default router
