// Contact form functions.

/**
 * Sends the contact form data.
 * @returns {boolean} true on success, false on failure
 */
export async function sendData() {
  try {
    var name = document.getElementById("name").value;
    var street = document.getElementById("mail").value;
    var phone = document.getElementById("phone").value;
    var zipcode = document.getElementById("address").value;
    var message = document.getElementById("message").value;

    var endpoint =
      "https://newform.de/contact.php";

    var body = new URLSearchParams();
    body.append("name", name);
    body.append("mail", street);
    body.append("phone", phone);
    body.append("address", zipcode);
    body.append("message", message);

    var lambdaRequest = new Request(endpoint, {
      method: "POST",
      body: body,
    });

    const response = await fetch(lambdaRequest);
    return response.ok;
  } catch {
    return false;
  }
}

/**
 * Validates an e-mail.
 * @param {String} email 
 * @returns 
 */
export function validEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}