<template>
  <main class="text-center">
    <div class="bg-primary py-3 pb-2">
      <div class="container">
        <h1>{{ $t("Privacy.Header.Title") }}</h1>
        <p>{{ $t("Privacy.Header.Description") }}</p>
      </div>
    </div>
    <div class="container text-start">
      <div class="row mb-5 mt-5">
        <div class="col-sm">
          <h3>{{ $t("Privacy.Responsibility.Title") }}</h3>
          <p>
            {{ $t("General.Address.Company") }}<br />
            {{ $t("General.Address.Street") }}<br />
            {{ $t("General.Address.Location") }}<br />
            {{ $t("General.Address.Country") }}<br />
            E-Mail: davidg@newform.de
          </p>
        </div>
      </div>
      <hr />
      <div class="row mt-5">
        <div class="col-sm">
          <h3>{{ $t("Privacy.Datatypes[0]") }}</h3>
          <ul>
            <li>{{ $t("Privacy.Datatypes[1]") }}</li>
          </ul>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm">
          <h3>{{ $t("Privacy.Purpose.Title") }}</h3>
          <p>
            {{ $t("Privacy.Purpose.Descriptions[0]") }}
          </p>
          <ol>
            <li>
              <span class="fw-bold">{{
                $t("Privacy.Purpose.Purposes[0]")
              }}</span
              ><br />
              {{ $t("Privacy.Purpose.Purposes[1]") }}
            </li>
          </ol>
          <p>
            {{ $t("Privacy.Purpose.Descriptions[1]") }}
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm">
          <h3>{{ $t("Privacy.Duration.Title") }}</h3>
          <p>
            {{ $t("Privacy.Duration.Description") }}
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm">
          <h3>{{ $t("Privacy.ThirdParties.Title") }}</h3>
          <p>
            {{ $t("Privacy.ThirdParties.Description") }}
          </p>
        </div>
      </div>
      <div class="row mb-5 mt-5">
        <div class="col-sm">
          <h3>{{ $t("Privacy.ThirdCountries.Title") }}</h3>
          <p>
            {{ $t("Privacy.ThirdCountries.Description") }}
          </p>
        </div>
      </div>
      <hr />
      <div class="row mb-5 mt-5">
        <div class="col-sm">
          <h3>{{ $t("Privacy.Complaints.Title") }}</h3>
          <p>
            {{ $t("Privacy.Complaints.Description[0]") }}
            <a
              rel="nofollow"
              href="https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_table.html"
              class="text-decoration-none"
              >{{ $t("Privacy.Complaints.Description[1]") }}</a
            >
          </p>
          <p class="fw-bold">
            {{ $t("Privacy.Complaints.Rights[0]") }}
          </p>
          <p>
            {{ $t("Privacy.Complaints.Rights[1]") }}
          </p>
          <ul>
            <li>{{ $t("Privacy.Complaints.Rights[2]") }}</li>
            <li>{{ $t("Privacy.Complaints.Rights[3]") }}</li>
            <li>{{ $t("Privacy.Complaints.Rights[4]") }}</li>
            <li>{{ $t("Privacy.Complaints.Rights[5]") }}</li>
            <li>{{ $t("Privacy.Complaints.Rights[6]") }}</li>
            <li>{{ $t("Privacy.Complaints.Rights[7]") }}</li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row mt-5">
        <div class="col-sm">
          <h3>{{ $t("Privacy.Changes.Title") }}</h3>
          <p>
            {{ $t("Privacy.Changes.Description") }}
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm">
          <p>{{ $t("Privacy.Time") }}</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("Privacy.Document.Title"));
  },
};
</script>