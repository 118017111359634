<template>
  <div>
    <nav
      id="pageNavbar"
      class="navbar navbar-expand-xl fixed-top bg-white shadow-sm p-0 py-3"
    >
      <div class="container-md">
        <router-link :to="'/' + $i18n.locale" class="navbar-brand">
          <img
            src="./assets/images/newform_logo.png"
            height="35"
            width="205"
            alt="Logo"
            class="d-inline-block"
          />
        </router-link>
        <button
          id="btnNavbarToggler"
          class="navbar-toggler btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            class="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            ></path>
          </svg>
        </button>
        <div
          class="collapse navbar-collapse flex-grow-0"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mx-auto mb-2 mb-md-0 gap-2">
            <li class="nav-item">
              <router-link
                :to="'/' + $i18n.locale"
                exact-active-class="custom-active"
                class="btn fw-bold w-100 text-start text-primary"
                >{{ $t("Navigation.Home") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :to="'/' + $i18n.locale + '/products'"
                exact-active-class="custom-active"
                class="btn fw-bold w-100 text-start text-primary"
                >{{ $t("Navigation.Products") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :to="'/' + $i18n.locale + '/news'"
                active-class="custom-active"
                class="btn fw-bold w-100 text-start text-primary"
                >{{ $t("Navigation.News") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :to="'/' + $i18n.locale + '/application'"
                exact-active-class="custom-active"
                class="btn fw-bold w-100 text-start text-primary"
                >{{ $t("Navigation.Application") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :to="'/' + $i18n.locale + '/aboutus'"
                exact-active-class="custom-active"
                class="btn fw-bold w-100 text-start text-primary text-nowrap"
                >{{ $t("Navigation.AboutUs") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :to="'/' + $i18n.locale + '/contact'"
                exact-active-class="custom-active"
                class="btn fw-bold w-100 text-start text-primary text-nowrap"
                >{{ $t("Navigation.Contact") }}</router-link
              >
            </li>
          </ul>
          <div class="dropdown mx-lg-3 my-1">
            <button
              class="btn btn-info rounded-pill text-white dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ getCurrentLanguage() }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li v-for="lang in languages" :key="lang">
                <button
                  :id="lang"
                  @click="changeLanguage(lang)"
                  :value="lang"
                  class="dropdown-item rounded-pill border-1 border-dark"
                >
                  {{ getLanguage(lang) }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <router-view class="custom-navbar-padding" />
    <footer
      class="
        custom-shadow
        p-3
        custom-bg-primary
        text-white
        small
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-4 mb-1">
            <ul class="list-group list-group-flush text-white">
              <li class="list-group-item border-0 p-0 bg-transparent text-white">
                <b>{{ $t("Footer.Contact.Title") }}</b>
              </li>
              <li class="list-group-item border-0 p-0 bg-transparent text-white">
                <p>
                  {{ $t("General.Address.Company") }}<br />
                  {{ $t("General.Address.Street") }}<br />
                  {{ $t("General.Address.Location") }}<br />
                  {{ $t("General.Address.Country") }}
                </p>
              </li>
              <li class="list-group-item border-0 p-0 bg-transparent text-white">
                <p>
                  {{ $t("General.PhoneNumbers[0]") }}<br />
                  {{ $t("General.PhoneNumbers[1]") }}
                </p>
              </li>
              <li class="list-group-item border-0 p-0 bg-transparent">
                <a href="mailto:davidg@newform.de" class="text-white">
                  davidg@newform.de
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 mb-1">
            <ul class="list-group list-group-flush">
              <li class="list-group-item border-0 p-0 bg-transparent text-white">
                <b>{{ $t("Footer.Newform.Title") }}</b>
              </li>
              <li class="list-group-item border-0 p-0 bg-transparent ">
                <p>
                  <router-link
                    :to="'/' + $i18n.locale + '/privacy'"
                    class="text-white"
                    >{{ $t("Navigation.Privacy") }}</router-link
                  >
                  <br />
                  <router-link
                    :to="'/' + $i18n.locale + '/contact'"
                    class="text-white"
                    >{{ $t("Navigation.Contact") }}</router-link
                  >
                  <br />
                  <router-link
                    :to="'/' + $i18n.locale + '/legalnotice'"
                    class="text-white"
                    >{{ $t("Navigation.LegalNotice") }}</router-link
                  >
                </p>
              </li>
              <li class="list-group-item border-0 p-0 bg-transparent text-white">
                <p>
                  {{ $t("Footer.Social.Title") }}
                  <br />
                  <a
                    href="https://de.linkedin.com/in/david-s-gower-2b22aa2a"
                    class="text-white"
                    >{{ $t("Footer.Social.Links[0]") }}</a
                  >
                </p>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 mb-1">
            <ul class="list-group list-group-flush">
              <li class="list-group-item border-0 p-0 bg-transparent text-white">
                <b>{{ $t("Footer.Affiliates.Title") }}</b>
              </li>
              <li class="list-group-item border-0 p-0 bg-transparent">
                <p>
                  <a href="www.meft.de" class="text-white">MEFT</a>
                  <br />
                  <a href="germany.tie.org" class="text-white">TiE Germany</a>
                  <br />
                  <a href="https://www.ecos-office.com/de/standorte/darmstadt/" class="text-white">ecos office center</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    <footer
      class="
        custom-shadow
        p-1
        bg-light
        text-white text-center
      "
    >
      <div class="container">
        <a href="https://www.dengge.de" class="btn">
          <small class="text-white" v-html="$t('General.Dengge')"></small>
          <div class="text-nowrap d-inline-block">
            <img
              src="./assets/images/dengge_logo_white.png"
              alt="Dengge Logo"
              width="33"
              height="40"
              class="ms-2"
            />
            <img
              src="./assets/images/dengge_logo_text_white.png"
              alt="Dengge Text Logo"
              width="147"
              height="20"
              class="ms-2"
            />
          </div>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import { Trans } from "./js/translation";

export default {
  name: "App",
  mounted() {
    var navLinks = document.querySelectorAll(".nav-item");
    navLinks.forEach((l) => {
      l.addEventListener("click", () => {
        document.getElementById("btnNavbarToggler").click();
      });
    });
  },
  methods: {
    changeLanguage(lang) {
      const to = this.$router.resolve({ params: { lang } });
      window.location.href = to.path;
    },
    getLanguage(lang) {
      switch (lang) {
        case "en":
          return "English";
        case "de":
          return "Deutsch";
        case "es":
          return "Español";
        case "fr":
          return "Français";
        case "it":
          return "Italiano";
        case "ru":
          return "русский";
      }
    },
    getCurrentLanguage() {
      const lang = Trans.currentLanguage;

      switch (lang) {
        case "en":
          return "English";
        case "de":
          return "Deutsch";
        case "es":
          return "Español";
        case "fr":
          return "Français";
        case "it":
          return "Italiano";
        case "ru":
          return "русский";
      }
    },
  },
  data() {
    return {
      languages: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },
};
</script>
<style scoped>
.custom-navbar-padding {
  padding-top: 5rem;
}

.custom-shadow {
  box-shadow: 0 -0.3rem 0.3rem rgb(0 0 0 / 18%);
}
.custom-footer-margin {
  margin-bottom: 3.9rem;
}
</style>