// Localization functions

/**
 * Sets the title of the current document.
 * @param {String} newTitle 
 */
export function setDocumentTitle(newTitle) {
    document.title = newTitle
}

/**
 * Sets the lang attribute of the current document.
 * @param {String} lang 
 */
export function setDocumentLang(lang) {
    document.documentElement.lang = lang
}