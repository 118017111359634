<template>
  <main class="text-center">
    <div class="container pt-5 pb-5">
      <h1>{{ $t("Home.Header.Title") }}</h1>
      <p class="my-4 mx-sm-5 px-2 px-sm-5">
        {{ $t("Home.Header.Description") }}
      </p>
      <div class="row py-2">
        <div class="col">
          <div class="ratio ratio-16x9 p-0">
            <iframe
              src="https://www.youtube-nocookie.com/embed/8CxmEIaBxjI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col">
          <div class="ratio ratio-16x9 p-0">
            <label class="custom-video-title text-start h-auto w-auto">{{
              $t("General.Video.Titles[0]")
            }}</label>
            <video poster="../assets/images/GIFA2023-thumbnail.jpg" controls>
              <source src="../assets/videos/GIFA2023.mp4" type="video/mp4" />
              {{ $t("General.Video.NotSupported") }}
            </video>
          </div>
        </div>
      </div>
      <div class="row py-2">
        <div class="col">
          <div class="ratio ratio-16x9 p-0">
            <label class="custom-video-title text-start h-auto w-auto">{{
              $t("General.Video.Titles[2]")
            }}</label>
            <video poster="../assets/images/GIFA2019-thumbnail.jpg" controls>
              <source src="../assets/videos/GIFA2019.mp4" type="video/mp4" />
              {{ $t("General.Video.NotSupported") }}
            </video>
          </div>
        </div>
        <div class="col">
          <div class="ratio ratio-16x9 p-0">
            <label class="custom-video-title text-start h-auto w-auto">{{
              $t("General.Video.Titles[1]")
            }}</label>
            <video poster="../assets/images/product_quality_message_thumbnail.jpg" controls>
              <source src="../assets/videos/Glass_Mica_letering.mp4" type="video/mp4" />
              {{ $t("General.Video.NotSupported") }}
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-secondary pt-2 pb-5">
      <div class="container">
        <h2>{{ $t("News.Header.Title") }}</h2>
        <div class="card-group flex-column flex-lg-row gap-5">
          <!-- <div class="card bg-transparent border-0">
            <div class="card-header custom-bg border-0">
              {{ $t("Home.News[0].Title") }}
            </div>
            <div class="card-body custom-bg border-0">
              {{ $t("News.Years[0].News[0].Content") }}
            </div>
            <div class="card-footer custom-bg border-0">
              <router-link :to="'/' + $i18n.locale + '/news'" class="p-0">{{
                $t("General.ReadMore")
              }}</router-link>
            </div>
          </div> -->
          <div class="card bg-transparent border-0">
            <div class="card-header custom-bg border-0">
              {{ $t("Home.News[1].Title") }}
            </div>
            <div class="card-body custom-bg border-0">
              {{ $t("News.Years[1].News[0].Content") }}
            </div>
            <div class="card-footer custom-bg border-0">
              <router-link
                :to="'/' + $i18n.locale + '/news/gifa-2019'"
                class="p-0"
                >{{ $t("General.ReadMore") }}</router-link
              >
            </div>
          </div>
          <div class="card bg-transparent border-0">
            <div class="card-header custom-bg border-0">
              {{ $t("Home.News[2].Title") }}
            </div>
            <div class="card-body custom-bg border-0">
              {{ $t("News.Years[2].News[1].Content") }}
            </div>
            <div class="card-footer custom-bg border-0">
              <router-link
                :to="'/' + $i18n.locale + '/news/gifa-2015'"
                class="p-0"
                >{{ $t("General.ReadMore") }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-info pt-2 pb-5">
      <div class="container">
        <h2>{{ $t("Products.Header.Title") }}</h2>
        <div class="card-group flex-column flex-lg-row gap-5">
          <div class="card bg-transparent border-0">
            <div class="card-header custom-bg border-0">
              {{ $t("Home.Products[0].Title") }}
            </div>
            <div class="card-body p-0 custom-bg border-0 d-flex align-items-center">
              <img
                src="../assets/images/mica.jpg"
                alt="Combi G picture"
                width="280"
                height="409"
                class="m-auto"
              />
            </div>
            <div class="card-footer custom-bg border-0">
              <router-link :to="'/' + $i18n.locale + '/products'" class="p-0">{{
                $t("General.ReadMore")
              }}</router-link>
            </div>
          </div>
          <div class="card bg-transparent border-0">
            <div class="card-header custom-bg border-0">
              {{ $t("Home.Products[1].Title") }}
            </div>
            <div class="card-body p-0 custom-bg border-0 d-flex align-items-center">
              <img
                width="410"
                height="275"
                alt="Foli PGM picture"
                src="../assets/images/foilpgm.jpg"
                class="m-auto custom-image-size"
              />
            </div>
            <div class="card-footer custom-bg border-0">
              <router-link :to="'/' + $i18n.locale + '/products'" class="p-0">{{
                $t("General.ReadMore")
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("Home.Document.Title"));
  },
};
</script>

<style scoped>
.custom-bg {
  background-color: rgba(0, 0, 0, 0.15);
}
</style>