<template>
  <main class="text-center">
    <div class="bg-primary py-3 pb-2">
      <div class="container">
        <h1>{{ $t("Application.Header.Title") }}</h1>
      </div>
    </div>
    <div class="container-lg">
      <div class="row gap-1 my-5 mx-1">
        <div class="col-lg mb-3 mb-sm-0">
          <img
            class="border custom-image-size"
            width="740"
            height="500"
            alt="Newform Combi G picture"
            src="../assets/images/application.jpg"
          />
        </div>
        <div class="col-lg text-start">
          <h3 v-html="$t('Application.Products[0].Title')"></h3>
          <p>
            {{ $t("Application.Products[0].Description") }}
          </p>
          <router-link
            :to="
              '/' +
              $i18n.locale +
              '/news/mica-slip-plane-for-ferrous-and-non-ferrous-foundries'
            "
            class="mb-1 d-block"
            >{{ $t("General.ReadMore") }}</router-link
          >
          <a
            :href="'/files/' + $i18n.locale + '/CombiGApplication.pdf'"
            class="btn btn-primary fw-bold text-white text-nowrap px-3 mb-1"
            download
          >
            {{ $t("Application.Buttons[0]") }}
            <i class="bi bi-download"></i>
          </a>
          <br />
          <a
            :href="'/files/' + $i18n.locale + '/Editorial.pdf'"
            class="btn btn-primary fw-bold text-white text-nowrap px-3"
            download
          >
            {{ $t("Application.Buttons[1]") }}
            <i class="bi bi-download"></i>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("Application.Document.Title"));
  },
};
</script>