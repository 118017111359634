<template>
  <main class="text-center">
    <div class="bg-primary py-3 pb-2">
      <div class="container">
        <h1>{{ $t("AboutUs.Header.Title") }}</h1>
      </div>
    </div>
    <div class="container">
      <div class="row my-5 mx-1 text-start">
        <div class="col-xl-6">
          <h2>{{ $t("AboutUs.Title") }}</h2>
          <p>{{ $t("AboutUs.Description[0]") }}</p>
          <p>{{ $t("AboutUs.Description[1]") }}</p>
          <p>{{ $t("AboutUs.Description[2]") }}</p>
          <p>{{ $t("AboutUs.Description[3]") }}</p>
          <p>{{ $t("AboutUs.Description[4]") }}</p>
          <p>{{ $t("AboutUs.Description[5]") }}</p>
        </div>
        <div class="col-xl-6">
          <img
            src="../assets/images/Philosophy.jpg"
            alt="Philosophy picture"
            width="500"
            height="667"
            class="d-block m-auto custom-image-size"
          />
        </div>
      </div>
      <div
        class="row gap-1 my-5 mx-1 border-top border-bottom border-primary bg-primary border-2"
      >
        <h2 class="text-primary pt-2">{{ $t("AboutUs.Philosophy.Title") }}</h2>
        <p>{{ $t("AboutUs.Philosophy.Quote") }}</p>
        <small class="pb-2">{{ $t("AboutUs.Philosophy.Source") }}</small>
      </div>
      <div class="container gap-1 my-5 mx-1 overflow-hidden">
        <h2 class="text-start">{{ $t("AboutUs.Team.Title") }}</h2>
        <div class="row g-10">
          <div class="col-md-6 col-lg-4 my-1">
            <div class="card border-dark">
              <img
                src="../assets/images/dgower_team.jpg"
                alt="David S. Gower picture"
                width="200"
                height="200"
                class="d-inline-block align-self-center my-3 p-0 rounded-circle img-thumbnail border-dark"
              />
              <b>{{ $t("AboutUs.Team.Member[0].Name") }}</b>
              <p class="pt-3">{{ $t("AboutUs.Team.Member[0].Description") }}</p>
              <a href="mailto:davidg@newform.de" class="pb-3">
                davidg@newform.de
              </a>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 my-1">
            <div
              class="card border-dark"
            >
              <img
                src="../assets/images/romain.png"
                alt="David S. Gower picture"
                width="200"
                height="200"
                class="d-inline-block align-self-center my-3 p-0 rounded-circle img-thumbnail border-dark"
              />
              <b>{{ $t("AboutUs.Team.Member[1].Name") }}</b>
              <p class="pt-3">{{ $t("AboutUs.Team.Member[1].Description") }}</p>
              <a href="mailto:daromain@aol.com" class="pb-3">
                daromain@aol.com
              </a>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 my-1">
            <img
              src="../assets/images/foundry.jpg"
              alt="Foundry picture"
              width="511"
              height="602"
              class="d-inline-block custom-image-size align-self-center"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("AboutUs.Document.Title"));
  },
};
</script>