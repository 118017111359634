<template>
  <main class="text-center">
    <div class="bg-primary py-3 pb-2">
      <div class="container">
        <h1>{{ $t("LegalNotice.Header.Title") }}</h1>
      </div>
    </div>
    <div class="container">
      <div class="row mb-5 mt-5 text-start">
        <div class="col-sm">
          <p>
            <b>{{ $t("LegalNotice.Responsibility.Title") }}</b
            ><br />
            {{ $t("General.Address.Street") }}<br />
            {{ $t("General.Address.Location") }}<br />
            {{ $t("General.Address.Country") }}
          </p>
          <div class="row">
            <div class="col-md-4 fw-bold">
              {{ $t("LegalNotice.ResponsibilityTitle.Inhaber") }}
            </div>
            <div class="col-md-4">
              {{ $t("LegalNotice.Responsibility.Inhaber") }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 fw-bold">
              {{ $t("LegalNotice.ResponsibilityTitle.Verantwortlich") }}
            </div>
            <div class="col-md-4 mt-auto">
              {{ $t("LegalNotice.Responsibility.Verantwortlich") }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 fw-bold">
              {{ $t("LegalNotice.ResponsibilityTitle.Amtsgericht") }}
            </div>
            <div class="col-md-4">
              {{ $t("LegalNotice.Responsibility.Amtsgericht") }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 fw-bold">
              {{ $t("LegalNotice.ResponsibilityTitle.VATNumber") }}
            </div>
            <div class="col-md-4">
              {{ $t("LegalNotice.Responsibility.VATNumber") }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 fw-bold">
              {{ $t("LegalNotice.ResponsibilityTitle.Telefon") }}
            </div>
            <div class="col-md-4">
              {{ $t("LegalNotice.Responsibility.Telefon") }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 fw-bold">
              {{ $t("LegalNotice.ResponsibilityTitle.Telefax") }}
            </div>
            <div class="col-md-4">
              {{ $t("LegalNotice.Responsibility.Telefax") }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 fw-bold">E-Mail:</div>
            <div class="col-md-4">
              <a href="mailto:davidg@newform.de">
                davidg@newform.de
              </a>
            </div>
          </div>
          <p>
            <br /><br />
            <b>{{ $t("LegalNotice.Disclaimer.Title") }}</b
            ><br />
            {{ $t("LegalNotice.Disclaimer.Inhalt[0]") }}<br /><br />
            {{ $t("LegalNotice.Disclaimer.Inhalt[1]") }}<br /><br />
            {{ $t("LegalNotice.Disclaimer.Inhalt[2]") }}<br /><br />
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("LegalNotice.Document.Title"));
  },
};
</script>