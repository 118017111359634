<template>
  <main class="text-center">
    <div class="bg-secondary py-3 pb-2">
      <div class="container">
        <h1>{{ $t("News.News[0].Title") }}</h1>
      </div>
    </div>
    <div class="container text-start">
      <div class="row my-4">
        <p>
          {{ $t("News.Years[2].News[0].Metadata") }}
          <br />
          {{ $t("News.News[0].Content[1]") }}
        </p>
        <p class="fw-bold">{{ $t("News.News[0].Content[2]") }}</p>
        <p>{{ $t("News.News[0].Content[3]") }}</p>
        <p>{{ $t("News.News[0].Content[4]") }}</p>
        <p>{{ $t("News.News[0].Content[5]") }}</p>
        <p>{{ $t("News.News[0].Content[6]") }}</p>
        <p>{{ $t("News.News[0].Content[7]") }}</p>
        <p>{{ $t("News.News[0].Content[8]") }}</p>
        <p class="fw-bold">{{ $t("News.News[0].Content[9]") }}</p>
        <p>{{ $t("News.News[0].Content[10]") }}</p>
        <p>{{ $t("News.News[0].Content[11]") }}</p>
        <p>{{ $t("News.News[0].Content[12]") }}</p>
        <p>
          {{ $t("News.News[0].Content[13]") }}
          <br />
          <a href="/">{{ $t("News.News[0].Content[14]") }}</a>
          <br />
          {{ $t("News.News[0].Content[15]") }}
        </p>
      </div>
      <div class="my-4">
        <img
          src="../../assets/images/mica.jpg"
          alt="Mica wallpaper image"
          width="280"
          height="409"
          class="d-inline-block p-1 custom-image-size"
        />
        <img
          src="../../assets/images/strips.jpg"
          alt="Strips image"
          width="550"
          height="409"
          class="d-inline-block p-1 custom-image-size"
        />
        <img
          src="../../assets/images/muscovite.jpg"
          alt="Muscovite image"
          width="450"
          height="409"
          class="d-inline-block p-1 custom-image-size"
        />
      </div>
      <div class="row my-4">
        <p>
          {{ $t("News.News[0].Content[16]") }}
        </p>
        <p>{{ $t("News.News[0].Content[17]") }}</p>
        <ul class="ms-sm-4">
          <li v-html="$t('News.News[0].Content[18]')"></li>
          <li v-html="$t('News.News[0].Content[19]')"></li>
          <li v-html="$t('News.News[0].Content[20]')"></li>
          <li v-html="$t('News.News[0].Content[21]')"></li>
        </ul>
        <p>
          {{ $t("News.News[0].Content[22]") }}
          <br />
          {{ $t("News.News[0].Content[23]") }}
          <br />
          {{ $t("News.News[0].Content[24]") }}
        </p>
        <router-link :to="'/' + $i18n.locale + '/news'" class="btn btn-primary w-auto ms-1 ms-sm-0">
          <i class="bi bi-arrow-return-left pe-1"></i
          >{{ $t("Navigation.Back") }}
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("News.News[0].Document.Title"));
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
ul > li:before {
  content: "- ";
}
</style>