<template>
  <main class="text-center">
    <div class="bg-secondary py-3 pb-2">
      <div class="container">
        <h1>{{ $t("News.News[2].Title") }}</h1>
      </div>
    </div>
    <div class="container text-start">
      <div class="row mt-4">
        <p v-html="$t('News.Years[1].News[0].Metadata')"></p>
        <div class="col-xl-6">
          <img
            src="../../assets/images/gifa2019_team.jpg"
            alt="GIFA 2019 news picture"
            width="505"
            height="128"
            class="d-inline-block p-1 mb-3 custom-image-size"
          />
          <p v-html="$t('News.News[2].Content[0]')"></p>
          <router-link
            :to="'/' + $i18n.locale + '/news'"
            class="btn btn-primary w-auto d-none d-xl-inline-block"
          >
            <i class="bi bi-arrow-return-left pe-1"></i
            >{{ $t("Navigation.Back") }}
          </router-link>
        </div>
        <div class="col-xl-6 mb-3">
          <img
            src="../../assets/images/GIFA2019_flyer.jpg"
            alt="GIFA 2019 news picture"
            width="545"
            height="768"
            class="d-inline-block p-1 custom-image-size"
          />
        </div>
      </div>
      <div class="row d-xl-none my-3">
        <router-link
          :to="'/' + $i18n.locale + '/news'"
          class="btn btn-primary w-auto ms-1 ms-sm-0"
        >
          <i class="bi bi-arrow-return-left pe-1"></i
          >{{ $t("Navigation.Back") }}
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("News.News[2].Document.Title"));
  },
};
</script>