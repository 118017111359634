import { createApp } from 'vue';
import App from './App.vue';

import router from './router';

import './scss/custom.scss';
import 'bootstrap';

import {i18n} from './i18n';

createApp(App).use(router).use(i18n).mount('#app');
