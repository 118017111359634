<template>
  <main class="text-center">
    <div class="bg-info py-3 pb-2">
      <div class="container">
        <h1>{{ $t("Products.Header.Title") }}</h1>
        <p class="mx-5 px-sm-5 text-nowrap">
          {{ $t("Products.Header.Description") }}
        </p>
      </div>
    </div>
    <div class="container">
      <div class="row my-5 mx-1 border-start border-primary">
        <div
          class="col-lg order-last order-lg-first text-start align-self-center"
        >
          <h2 v-html="$t('Products.Products[1].Title')"></h2>
          <b> {{ $t("Products.Products[0].Description") }}</b>
          <p>
            {{ $t("Products.Products[1].Description") }}
          </p>
          <b> {{ $t("Products.Products[0].Application") }}</b>
          <p>
            {{ $t("Products.Products[1].Application") }}
          </p>
          <a
            :href="'/files/' + $i18n.locale + '/CombiG.zip'"
            class="btn btn-primary fw-bold text-white text-nowrap px-3 mb-1"
            download
          >
            {{ $t("General.Download") }}
            <i class="bi bi-download"></i>
          </a>
          <br />
          <router-link
            :to="'/' + $i18n.locale + '/contact'"
            exact-active-class="custom-active"
            class="btn btn-primary fw-bold text-nowrap"
            >{{ $t("Navigation.Contact") }}</router-link
          >
        </div>
        <div class="col-lg order-first order-lg-last mb-3 mb-sm-0 pb-2">
          <img
            src="../assets/images/mica.jpg"
            alt="Combi G picture"
            width="280"
            height="409"
            class="custom-image-size"
          />
        </div>
      </div>
      <div class="row my-5 mx-1 border-start border-primary">
        <div
          class="col-lg order-last order-lg-first text-start align-self-center"
        >
          <h2 v-html="$t('Products.Products[2].Title')"></h2>
          <b> {{ $t("Products.Products[0].Description") }}</b>
          <p>
            {{ $t("Products.Products[2].Description") }}
          </p>
          <b> {{ $t("Products.Products[0].Application") }}</b>
          <p>
            {{ $t("Products.Products[2].Application") }}
          </p>
          <a
            :href="'/files/' + $i18n.locale + '/FoilPGM.zip'"
            class="btn btn-primary fw-bold text-white text-nowrap px-3 mb-1"
            download
          >
            {{ $t("General.Download") }}
            <i class="bi bi-download"></i>
          </a>
          <br />
          <router-link
            :to="'/' + $i18n.locale + '/contact'"
            exact-active-class="custom-active"
            class="btn btn-primary fw-bold text-nowrap"
            >{{ $t("Navigation.Contact") }}</router-link
          >
        </div>
        <div class="col-lg order-first order-lg-last mb-3 mb-sm-0 pb-2">
          <img
            width="410"
            height="275"
            alt="Foli PGM picture"
            src="../assets/images/foilpgm.jpg"
            class="custom-image-size"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("Products.Document.Title"));
  },
};
</script>