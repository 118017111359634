<template>
  <main class="text-center">
    <div class="bg-secondary py-3 pb-2">
      <div class="container">
        <h1>{{ $t("News.News[1].Title") }}</h1>
      </div>
    </div>
    <div class="container text-start">
      <div class="row my-4">
        <p v-html="$t('News.Years[2].News[1].Metadata')"></p>
        <p class="col-lg-6" v-html="$t('News.News[1].Content[0]')"></p>
        <div class="col-lg-6 mb-3 order-first order-lg-last">
          <img
            src="../../assets/images/GIFA2015_team.jpg"
            alt="GIFA 2015 news picture"
            width="540"
            height="150"
            class="d-inline-block p-1 custom-image-size"
          />
        </div>
      </div>
      <div class="row my-4">
        <router-link
          :to="'/' + $i18n.locale + '/news'"
          class="btn btn-primary w-auto ms-1 ms-sm-0"
        >
          <i class="bi bi-arrow-return-left pe-1"></i
          >{{ $t("Navigation.Back") }}
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { setDocumentTitle } from "../../js/localization";

export default {
  mounted() {
    setDocumentTitle(this.$t("News.News[1].Document.Title"));
  },
};
</script>